import React from "react";
import {
  FacebookShareButton,
  TelegramShareButton,
  FacebookIcon,
  TelegramIcon,
  TwitterShareButton,
  TwitterIcon,
} from "react-share";

const Share = ({ slug, title }) => {
  return (
    <div style={{ marginBottom: `14px` }}>
      <span style={{ display: `block`, marginBottom: `10px` }}>Поширити :</span>
      <FacebookShareButton url={slug} quote={title} className="share--item">
        <FacebookIcon size={32} round />
      </FacebookShareButton>
      <TelegramShareButton url={slug} title={title} className="share--item">
        <TelegramIcon size={32} round />
      </TelegramShareButton>
      <TwitterShareButton url={slug} title={title} className="share--item">
        <TwitterIcon size={32} round />
      </TwitterShareButton>
    </div>
  );
};

export default Share;
