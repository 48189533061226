import React from "react";
import { HelmetDatoCms } from "gatsby-source-datocms";
import Layout from "../components/Layout";
import BreadCrumps from '../ui/breadCrumps/BreadCrumps'
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Share from '../ui/share/Share';
import {graphql} from "gatsby";

export default function Post({ data: { site, post,  mainsite} }) {
    const shareUrl = `${mainsite.siteMetadata.siteUrl}/news/${post.slug}`
    return (
        <Layout>
            <HelmetDatoCms seo={post.seo} favicon={site.favicon} />
            <BreadCrumps
                title="НОВИНИ"
            />
            <section className="single-section">
                <div className="post--container">
                    <div className="post-content">
                        <div className="single-page-image-wrapper">
                            <GatsbyImage image={getImage(post.coverImage.gatsbyImageData)} alt={post.title + "image"} />
                        </div>
                        <h1 className="single-page-title">
                            {post.title}
                        </h1>
                        <span>
                            {post.data}
                        </span>
                        <Share
                            slug={shareUrl}
                            title={post.title}
                            details=""
                        />
                        <div
                            key={`body`}
                            dangerouslySetInnerHTML={{ __html: post.innerhtml }}
                        >
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    );
}

export const query = graphql`
    query PostBySlug($slug: String ) {
        site: datoCmsSite {
            favicon: faviconMetaTags {
                ...GatsbyDatoCmsFaviconMetaTags
            }
        }
        mainsite: site {
                siteMetadata {
                siteUrl
            }
        }
        post: datoCmsPost(slug: { eq: $slug }) {
            seo: seoMetaTags {
                ...GatsbyDatoCmsSeoMetaTags
            }
            title
            slug
            data
            innerhtml
            coverImage {
                gatsbyImageData
            }
        }
    }
`;
